<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Tamamlanan Talepler </template>
      <template #content>
        <TalepDataTable :columns="columns" :data="TalepList" v-on:set-child-data="getDataChild" :ShowDurum="true"/>
      </template>
    </Card>
  </div>
</template>

<script>
import TalepDataTable from "../../DataTable/TalepDataTable.vue";
import GlobalServis from "../../../../services/GlobalServis";

export default {
  data() {
    return {
      DurumList: [],
      TalepList: [],
      columns: [
        { field: "MusteriAd", header: "Musteri Adı" },
        { field: "GuncellemeTarih", header: "Tarih" },
        { field: "SehirAd", header: "Şehir" },
        { field: "IlceAd", header: "İlçe" },
        { field: "TurAd", header: "Urun Tur" },
        { field: "MarkaAd", header: "Marka" },
        { field: "ModelAd", header: "Model" },
        { field: "ServisAd", header: "Teknik Servis Adı" },
      ],
    };
  },
  methods: {
    InitServis() {
      let data = {
        Talep_rowid: this.Talep_rowid,
        Durum_rowid: this.Durum_rowid,
        BaslamaTarih: this.BaslamaTarih,
        BitisTarih: this.BitisTarih,
      };
      GlobalServis.GlobalServis("POST", "GetTamamlananTalep", data).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.TalepList = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
      GlobalServis.GlobalServis("GET", "GetDurum", "").then((res) => {
        if (res.status == 200) {
          this.DurumList = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    TalepDataTable,
  },
};
</script>

<style lang="scss" scoped>
.top-button-area {
  margin-bottom: 5px;
}

.getirbtn {
  margin-top: 20px;
}
</style>../../../../services/GlobalServis
